// composables/useTrackingId.js
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const trackingId = ref(null);
const isLoading = ref(true);

export function useTrackingId() {
  const mixpanel = useMixpanel();
  const route = useRoute();

  const initializeTracking = async () => {
    isLoading.value = true;
    try {
      let id = mixpanel.get_distinct_id() ?? '';
      console.debug('Mixpanel Distinct ID:', id);

      const gclid = route.query.gclid;
      const viewCampaignID = route.query.view_campaign_id;
      const tapfiliateRef = route.query.ref;
      console.debug('GCLID:', gclid);
      console.debug('View Campaign ID:', viewCampaignID);

      // Wait for Tapfiliate to be available
      await new Promise((resolve) => {
        if (window.tap && window.tap.vids) {
          resolve();
        } else {
          const checkTap = setInterval(() => {
            if (window.tap && window.tap.vids) {
              //Add tapfiliate VIDs to mixpanel properties
              mixpanel.register({
                tapfiliate_vids: window.tap.vids,
              });
              clearInterval(checkTap);
              resolve();
            }
          }, 100);
        }
      });

      if (window.tap.vids && window.tap.vids[0]) {
        id += encodeURIComponent('~ata~') + window.tap.vids[0];
      }

      if (tapfiliateRef) {
        //Register ref on mixpanel
        mixpanel.register({
          tapfiliate_ref: tapfiliateRef,
        });
      }

      //GTTD tracking: register GTTD_source, GTTD_funnel, GTTD_ads on mixpanel
      mixpanel.register({
        GTTD_source: route.query.GTTD_source ?? null,
        GTTD_funnel: route.query.GTTD_funnel ?? null,
        GTTD_ads: route.query.GTTD_ads ?? null,
        GTTD_product_id: route.query.GTTD_product_id ?? null,
        GTTD_src: route.query.GTTD_src ?? null,
      });

      if (gclid) {
        id += encodeURIComponent('~gid~') + gclid;
      }

      trackingId.value = id;

      if (viewCampaignID) {
        console.debug('Final Tracking ID:', trackingId.value);
        console.debug('Tracking ID:', trackingId.value);
      }

      console.debug('Current Tracking ID:', trackingId.value);
    } catch (error) {
      console.error('Failed to initialize tracking:', error);
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    if (process.client) {
      initializeTracking();
    }
  });

  watch(
    () => route.query,
    () => {
      if (process.client) {
        initializeTracking();
      }
    },
    { deep: true },
  );

  return {
    trackingId,
    isLoading,
  };
}
