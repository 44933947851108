// plugins/numberFormat.js
import { defineNuxtPlugin } from '#app';
import Cookies from 'js-cookie';
import { numberFormats } from '~/i18n/number-format'; // Import your custom number formats

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.mixin({
    methods: {
      localizedNumber(number, formatType, passedOptions) {
        const numberLocale = Cookies.get('preferredNumberLocale') || 'en-US';
        let numberFormat = numberFormats[numberLocale];
        if (!numberFormat) {
          console.error('Number format not found, using default format.');
          numberFormat = numberFormats['en-US'];
        }
        const options = { style: formatType };
        if (formatType === 'currency') {
          const currency = numberFormat.currency.currency ?? null;
          options.currencyDisplay = 'narrowSymbol';
          options.currency = currency;
        }

        //Add the options parameter passed to the function
        if (passedOptions) {
          Object.assign(options, passedOptions);
        }
        return new Intl.NumberFormat(numberLocale, options).format(number);
      },
      currentCurrency() {
        const numberLocale = Cookies.get('preferredNumberLocale') || 'en-US';
        let numberFormat = numberFormats[numberLocale];
        return numberFormat.currency.currency;
      },
    },
  });
});
