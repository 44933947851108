// plugins/logger.client.js

import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENV,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  });

  const logLevels = {
    DEBUG: 'debug',
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
  };

  const formatMessage = (args) => {
    return args
      .map((arg) => {
        if (typeof arg === 'object') {
          return JSON.stringify(arg, null, 2);
        }
        return arg;
      })
      .join(' ');
  };

  const log = (level, ...args) => {
    const message = formatMessage(args);
    const logMessage = {
      level,
      message,
      timestamp: new Date().toISOString(),
    };

    if (process.env.NODE_ENV !== 'production') {
      console[level](`${logMessage.timestamp} [${logMessage.level.toUpperCase()}]: ${logMessage.message}`);
    }

    Sentry.captureMessage(message, level);
  };

  const logger = {
    debug: (...args) => log(logLevels.DEBUG, ...args),
    info: (...args) => log(logLevels.INFO, ...args),
    warn: (...args) => log(logLevels.WARN, ...args),
    error: (...args) => log(logLevels.ERROR, ...args),
  };

  // Inject the logger into the Nuxt app
  nuxtApp.provide('log', logger);
});
