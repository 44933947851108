// plugins/tapfiliate.client.js
export default defineNuxtPlugin((nuxtApp) => {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = '/js/tapfiliate.js'; // Updated to use locally hosted file
      script.async = true;
      script.onload = () => {
        (function (t, a, p) {
          t.TapfiliateObject = a;
          t[a] =
            t[a] ||
            function () {
              (t[a].q = t[a].q || []).push(arguments);
            };
        })(window, 'tap');

        window.tap('create', '48947-8e4b50', { integration: 'javascript' });
        window.tap('detect');

        resolve();
      };
      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
});
