<template>
  <div class="w-full md:w-5/6 relative z-40 px-1">
    <form @submit.prevent="handleSubmit" @click="clickOnSearchBar()" class="z-50">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{{
        $t('Search')
      }}</label>
      <div class="relative" :class="isLandingPage ? 'block' : 'hidden md:block'">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            class="w-4 h-4 ml-2 text-gray-500 dark:text-Text-Grey_Secondary"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          ref="searchInput"
          type="search"
          id="default-search"
          :value="query"
          @input="handleInput"
          @keydown="handleKeydown"
          @focus="showSuggestions = true"
          class="no-clear-button h-12 block w-full py-4 ps-12 text-[3.2vw] sm:text-lg text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:border-Tourscanner_blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :placeholder="$t('Search and select a destination')"
          required
        />
        <button
          type="submit"
          class="text-white absolute end-[9px] bottom-[7px] right-[7px] bg-gradient-to-r from-ts_blue_to to-ts_blue_from hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {{ $t('Search') }}
        </button>
      </div>

      <div :class="isLandingPage ? 'hidden' : 'block md:hidden'">
        <button
          @click="showModal = true"
          class="border-1 text-gray-800 dark:text-white border-2 border-solid border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          <img src="/img/icons/search_B.svg" class="icon dark:filter dark:invert dark:invert-1 w-5 h-5" />
        </button>
      </div>
    </form>
    <ul
      v-if="!isSearchEmpty && showSuggestions"
      class="absolute f bg-white dark:bg-zinc-800 border border-gray-300 rounded-lg mt-2 w-full max-h-fit shadow-lg z-50"
      :style="{ width: resultsWidth + 'px' }"
    >
      <li
        v-for="(suggestion, index) in suggestions"
        :key="suggestion.id"
        :class="{ 'bg-gray-200 dark:bg-zinc-600 rounded-lg': index === selectedSuggestionIndex }"
        class="p-2 hover:bg-gray-200 dark:hover:bg-zinc-600 cursor-pointer flex items-center rounded-lg"
        @click="selectSearch(index)"
        :id="index"
      >
        <div class="flex items-center mr-2">
          <img
            class="icon dark:filter dark:invert dark:invert-1"
            :src="`/img/icons/${getIconName(suggestion.role)}.svg`"
          />
        </div>
        {{ suggestion.name }}
      </li>
      <li
        :class="{
          'bg-gray-200 dark:bg-zinc-600': selectedSuggestionIndex === suggestions.length,
        }"
        class="p-2 cursor-pointer flex items-center rounded-lg"
        @click="searchForQuery"
      >
        <div class="flex items-center mr-2">
          <img src="/img/icons/travel_explore.svg" class="icons mr-2 dark:filter dark:invert dark:invert-1" />
          {{ $t('Search all results for') }}
          <strong class="ml-1"
            ><i>{{ query }}</i></strong
          >
        </div>
      </li>
    </ul>
    <ul
      v-if="isSearchEmpty && !popularSearchesEmpty && showSuggestions"
      class="absolute f bg-white dark:bg-zinc-800 border border-gray-300 rounded-lg mt-2 w-full max-h-fit shadow-lg z-50 p-3"
      :style="{ width: resultsWidth + 'px' }"
    >
      <li v-for="(category, categoryIndex) in popularSearches" :key="categoryIndex">
        <div v-if="category.links.length > 0" class="p-2">
          <h3 class="font-semibold text-gray-700 dark:text-gray-300 mb-2">{{ category.title }}</h3>
          <ul class="flex flex-nowrap md:flex-wrap overflow-x-auto md:overflow-x-hidden">
            <li
              v-for="(link, linkIndex) in category.links"
              :key="linkIndex"
              class="p-2 px-5 hover:bg-gray-200 dark:hover:bg-zinc-600 cursor-pointer flex items-center rounded-full border mr-1 mt-2 leading-tight text-md"
              @click="selectSuggestion(link.url)"
            >
              {{ link.title }}
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <!-- Mobile Fullscreen Modal -->
    <!---------------->
    <!---------------->
    <!---------------->
    <!---------------->
    <!---------------->
    <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div class="bg-white dark:bg-zinc-800 w-full h-full p-2">
        <div class="flex">
          <button @click="closeModal" class="text-gray-500 hover:text-gray-900 mt-2 mr-2">
            <img src="/img/icons/chevron_left.svg" class="icon dark:filter dark:invert dark:invert-1" />
          </button>
          <input
            type="search"
            :value="query"
            @input="handleInput"
            @keydown="handleKeydown"
            class="no-clear-button h-12 block w-full py-4 ps-6 text-xl text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:border-Tourscanner_blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for cities or attractions"
            id="mobile-search"
            style="font-size: 1rem"
            required
          />
        </div>
        <ul v-if="!isSearchEmpty" class="mt-4">
          <li
            v-for="(suggestion, index) in suggestions"
            :key="suggestion.id"
            :class="{ 'pt-4': index != 0 }"
            class="cursor-pointer flex items-center"
            @click="selectSearch(index)"
          >
            <div class="flex items-center mr-2">
              <img
                :src="`/img/icons/${getIconName(suggestion.role)}.svg`"
                class="icon dark:filter dark:invert dark:invert-1"
              />
            </div>
            {{ suggestion.name }}
          </li>
          <li
            class="cursor-pointer flex items-center"
            :class="suggestions.length ? 'pt-6' : 'pt-0'"
            @click="searchForQuery"
          >
            <div class="flex items-start mr-2 px-1">
              <img src="/img/icons/travel_explore.svg" class="icon mr-2 dark:filter dark:invert dark:invert-1" />
              <span class="leading-tight text-md"
                >{{ $t('Search all results for') }}
                <strong class="ml-1"
                  ><i>{{ query }}</i></strong
                ></span
              >
            </div>
          </li>
        </ul>
        <ul
          v-if="isSearchEmpty && popularSearches.length > 0"
          class="absolute f bg-white dark:bg-zinc-800 mt-5 w-full max-h-fit z-50"
        >
          <li v-for="(category, categoryIndex) in popularSearches" :key="categoryIndex">
            <div v-if="category.links.length > 0" class="mb-5 px-2">
              <h3 class="font-semibold text-xl text-gray-700 dark:text-gray-300">{{ category.title }}</h3>
              <ul class="flex flex-nowrap overflow-x-auto">
                <li
                  v-for="(link, linkIndex) in category.links"
                  :key="linkIndex"
                  class="px-3 py-1 text-center hover:bg-gray-200 dark:hover:bg-zinc-600 cursor-pointer inline-flex items-center rounded-full border mr-1 mt-2 whitespace-nowrap leading-tight"
                  @click="selectSuggestion(link.url)"
                >
                  {{ link.title }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import debounce from 'lodash.debounce';
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const log = useLogger();

const searchInput = ref(null);
const resultsWidth = ref(0);

const props = defineProps({
  initialQuery: {
    type: String,
    default: '',
  },
  popularSearches: {
    type: Array,
    default: () => [],
  },
});

const query = ref(props.initialQuery);
const suggestions = ref([]);
const selectedSuggestionIndex = ref(0);
const previousQuery = ref('');
const showSuggestions = ref(false);
const showModal = ref(false);
const { locale } = useI18n();
const currentLang = locale.value;
const localePath = useLocalePath();
const route = useRoute();

const isSearchEmpty = computed(() => query.value.trim() === '');

const handleInput = (event) => {
  query.value = event.target.value;
  debounceFetchSuggestions();
};

// Update this watcher to react to initialQuery changes
watch(
  () => props.initialQuery,
  (newValue) => {
    query.value = newValue;
  },
);

//Watch for in the popularSearches array
watch(props.popularSearches, (newValue) => {
  props.popularSearches.value = newValue;
});

//Check if any link is in the sections and if sections is > 0
const popularSearchesEmpty = computed(() => {
  let empty = true;
  for (const section of props.popularSearches) {
    if (section.links.length > 0) {
      empty = false;
      break;
    }
  }
  return empty;
});

// Check if the path is homepage or search page
const isLandingPage = computed(() => {
  const pathName = route.name;
  return pathName.includes('search') || pathName.includes('index');
});

const itsSearchPage = computed(() => {
  const pathName = route.name;
  return pathName.includes('search');
});

const fetchSuggestions = async () => {
  if (query.value.trim() === '' || query.value === previousQuery.value) {
    return;
  }

  previousQuery.value = query.value;
  const { data } = await useFetch(`/api/suggestion`, {
    params: { query: query.value, lang: currentLang },
  });

  suggestions.value = data.value ?? [];
};

const debounceFetchSuggestions = debounce(fetchSuggestions, 300);

const clickOnSearchBar = () => {
  if (window.innerWidth < 768) {
    showModal.value = true;
    document.body.style.overflow = 'hidden'; // Disable scrolling
    window.scrollTo(0, 0); // Scroll to the top
    nextTick(() => {
      const searchMobile = document.getElementById('mobile-search');
      searchMobile.focus();
      searchMobile.select();
    });
  }
};

const handleKeydown = (event) => {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    if (selectedSuggestionIndex.value < suggestions.value.length) {
      selectedSuggestionIndex.value++;
    }
  } else if (event.key === 'ArrowUp') {
    event.preventDefault();
    if (selectedSuggestionIndex.value > 0) {
      selectedSuggestionIndex.value--;
    }
  } else if (event.key === 'Enter') {
    event.preventDefault();
    if (selectedSuggestionIndex.value < suggestions.value.length) {
      selectSearch(selectedSuggestionIndex.value);
    } else {
      searchForQuery();
    }
  } else if (event.key === 'Escape') {
    showSuggestions.value = false;
  }
};

const selectSearch = async (index) => {
  if (showModal.value == true) {
    closeModal();
  }
  const selected = suggestions.value[index];
  log.info('Selected search:', selected);
  document.body.style.overflow = 'auto'; // Enable scrolling
  // await navigateTo(localePath(selected.url));
  window.location.href = selected.url;
};

const selectSuggestion = async (link) => {
  if (showModal.value == true) {
    closeModal();
  }
  log.info('Selected suggestion:', link);
  document.body.style.overflow = 'auto'; // Enable scrolling
  window.location.href = link;
};

const handleSubmit = () => {
  log.info('Search submitted:', query.value);
  suggestions.value = [];
  showSuggestions.value = false;
};

const emit = defineEmits(['newSearch']);
const searchForQuery = async () => {
  log.info('Search for:', query.value);
  if (route.name.includes('search')) {
    closeModal();
    showSuggestions.value = false;
    document.activeElement.blur(); // This will remove focus from the active element (search bar)
    emit('newSearch', query.value);
  } else {
    log.info('Navigating to search page');
    navigateTo(localePath(`/search?q=${query.value}`));
  }
};

const closeModal = () => {
  showModal.value = false;
  showSuggestions.value = false;
  document.body.style.overflow = 'auto'; // Enable scrolling
};

const handleClickOutside = (event) => {
  const searchBar = document.getElementById('default-search');
  if (searchBar && !searchBar.contains(event.target)) {
    showSuggestions.value = false;
    document.body.style.overflow = 'auto'; // Enable scrolling
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  updateResultsWidth();
  window.addEventListener('resize', updateResultsWidth);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  window.removeEventListener('resize', updateResultsWidth);
});

const updateResultsWidth = () => {
  resultsWidth.value = searchInput.value.offsetWidth;
};

const getIconName = (role) => {
  if (role === 'mun') {
    return 'apartment';
  } else if (role === 'acr' || role === 'ctr') {
    return 'flag';
  } else if (role === 'idt') {
    return 'bookmark_flag';
  } else if (role === 'loc') {
    return 'location_on';
  } else if (role === 'cat') {
    return 'category';
  } else if (role === 'blog') {
    return 'book_2';
  } else {
    return 'data_object';
  }
};
</script>

<style scoped>
@media screen and (max-width: 490px) {
  #default-search {
    font-size: 4vw;
  }
}
/* Ensure modal takes full screen and disable body scrolling */
body.modal-open {
  overflow: hidden;
}

.fixed.inset-0 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.no-clear-button::-webkit-search-cancel-button,
.no-clear-button::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
</style>
