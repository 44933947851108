<template>
  <div class="p-4 md:p-5">
    <div class="flex items-center space-x-2 w-full mb-2">
      <img src="/img/icons/universal_currency_alt.svg" class="dark:filter dark:invert dark:invert-1" />
      <span class="text-lg font-semibold leading-tight">{{ $t('Currency') }}</span>
    </div>
    <div class="relative select-wrapper">
      <select
        v-model="selectedCurrency"
        @change="updateCurrency"
        class="select-class w-full border-2 bg-white dark:bg-gray-600 border-gray-300 dark:border-gray-600 p-5 md:py-3 rounded-full"
      >
        <option v-for="currency in availableCurrencies" :key="currency" :value="currency">
          {{ currency }}
        </option>
      </select>
      <img src="/img/icons/keyboard_arrow_down.svg" class="custom-arrow dark:filter dark:invert dark:brightness-0" />
    </div>
    <div class="flex items-center space-x-2 w-full mt-5 mb-2">
      <img src="/img/icons/translate.svg" class="dark:filter dark:invert dark:invert-1" />
      <span class="text-lg font-semibold leading-tight">{{ $t('Language') }}</span>
    </div>
    <div class="relative select-wrapper">
      <select
        v-model="selectedLanguage"
        @change="updateLocale"
        class="select-class w-full border-2 bg-white dark:bg-gray-600 border-gray-300 dark:border-gray-600 p-5 md:py-3 rounded-full"
      >
        <option v-for="language in languages" :key="language.code" :value="language.code">
          {{ language.name }}
        </option>
      </select>
      <img src="/img/icons/keyboard_arrow_down.svg" class="custom-arrow dark:filter dark:invert dark:brightness-0" />
    </div>
  </div>
</template>

<script setup>
import Cookies from 'js-cookie';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { setLocale, locales, numberFormats, locale } = useI18n();

const selectedCurrency = ref(null);
const selectedLanguage = ref(null);

const emit = defineEmits(['settingsUpdated']);

const updateCurrency = () => {
  const languageLocale = selectedLanguage.value;
  const numberLocale = getNumberLocaleForCurrencyAndLanguage(numberFormats, languageLocale, selectedCurrency.value);
  if (numberLocale) {
    Cookies.set('preferredNumberLocale', numberLocale, { expires: 365 });
    window.location.reload();
  }
  // Emit an event to notify the parent component that settings have been updated
  emit('settingsUpdated');
};

const updateLocale = () => {
  const languageLocale = selectedLanguage.value;

  // setLocale(languageLocale);
  Cookies.set('preferredLanguageLocale', languageLocale, { expires: 365 });

  // Log all hreflang links for debugging
  const allHreflangLinks = document.querySelectorAll('link[rel="alternate"][hreflang]');
  console.log('All hreflang links:', allHreflangLinks);

  // Find the appropriate hreflang link
  const hreflangLink = document.querySelector(`link[rel="alternate"][hreflang="${languageLocale}"]`);
  console.log('Matching hreflang link:', hreflangLink);

  if (hreflangLink) {
    //Navigate to the hreflang link
    //Remove the hostname from the href
    const href = hreflangLink.href.replace(window.location.origin, '');
    navigateTo(href);
  } else {
    console.error(`No matching hreflang link found for locale: ${languageLocale}`);
    // Optionally, list all available hreflang values
    const availableLocales = Array.from(allHreflangLinks).map((link) => link.getAttribute('hreflang'));
    console.log('Available locales:', availableLocales);
  }
  // Emit an event to notify the parent component that settings have been updated
  emit('settingsUpdated');
};

const languages = computed(() => {
  return locales.value.map((locale) => ({
    code: locale.code,
    name: locale.name,
  }));
});

const availableCurrencies = computed(() => {
  let currencyList = [];
  for (const key of Object.keys(numberFormats.value)) {
    const currency = numberFormats.value[key].currency.currency;
    if (currencyList.includes(currency)) continue;
    currencyList.push(currency);
  }

  return currencyList;
});

const getNumberLocaleForCurrencyAndLanguage = (numberFormats, languageLocale, currency) => {
  const languageCode = languageLocale.split('-')[0];
  return Object.keys(numberFormats.value).find(
    (locale) => locale.startsWith(languageCode) && numberFormats.value[locale].currency.currency === currency,
  );
};

onMounted(() => {
  const preferredNumberLocale = Cookies.get('preferredNumberLocale') || locale.value;
  selectedLanguage.value = locale.value;
  selectedCurrency.value = numberFormats.value[preferredNumberLocale]?.currency?.currency || 'USD';
});
</script>

<style scoped>
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-class {
  appearance: none;
}

.custom-arrow {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>
