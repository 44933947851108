<template>
  <NuxtLoadingIndicator />
  <AppHeader :data="headerData" />
  <NuxtLayout>
    <NuxtPage
      class="pt-2 md:pt-5 mx-auto max-w-screen-xl px-2 md:px-4 min-h-screen pb-20"
      @passDataToHeader="onPassDataToHeader"
    />
  </NuxtLayout>
  <AppFooter />
</template>
<script setup>
const headerData = ref({});
useTrackingId();

function onPassDataToHeader(data) {
  headerData.value = data;
}
</script>
<style>
h1 {
  @apply text-8xl;
  @apply font-semibold;
}
h2 {
  @apply text-4xl;
  @apply leading-8;
  @apply font-semibold;
  @apply md:text-4xl;
  @apply md:leading-10;
}
strong {
  @apply font-semibold;
}
h3 {
  @apply text-3xl;
  @apply font-semibold;
}
p {
  @apply text-xl;
  @apply leading-8;
}
a {
  @apply text-Tourscanner_blue-source hover:dark:text-gray-400;
}
li {
  @apply text-xl;
  @apply leading-8;
}
.ts-button {
  @apply bg-gradient-to-r from-ts_blue_to to-ts_blue_from;
  @apply rounded-full px-4 py-2;
  @apply text-white;
}

body {
  @apply dark:bg-[#121212] dark:text-white;
}
</style>
