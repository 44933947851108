<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      @click.self="closeModal"
    >
      <div
        :class="modalClasses"
        class="bg-white dark:bg-zinc-800 md:rounded-lg shadow-lg overflow-hidden h-full md:h-auto flex flex-col"
      >
        <div
          v-if="$slots.header"
          class="border-b border-gray-300 dark:border-gray-700 p-4 flex flex-row justify-between items-center"
        >
          <slot name="header"></slot>
          <button @click="closeModal" class="ml-4 text-gray-500 hover:text-gray-700">
            <img src="/img/icons/close.svg" class="dark:filter dark:invert dark:invert-1" />
          </button>
        </div>
        <div class="overflow-auto flex-1">
          <slot></slot>
        </div>
        <div v-if="$slots.footer" class="border-t p-4 flex-none">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);
const scrollPosition = ref(0);

const closeModal = () => {
  emit('close');
  enableScroll();
};

const disableScroll = () => {
  scrollPosition.value = window.pageYOffset;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPosition.value}px`;
  document.body.style.width = '100%';
};

const enableScroll = () => {
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = '';
  window.scrollTo(0, scrollPosition.value);
};

watch(
  () => props.isOpen,
  (newVal) => {
    if (newVal) {
      disableScroll();
    } else {
      enableScroll();
    }
  },
);

onMounted(() => {
  if (props.isOpen) {
    disableScroll();
  }
});

onUnmounted(() => {
  enableScroll();
});

const modalClasses = 'w-full md:max-w-96 h-full md:h-auto';
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
